.legal-page {
  padding-top: 60px;
}
@media (992px <= width) {
  .legal-page {
    padding-top: 80px;
  }
}
.legal-page h1, .legal-page p {
  margin: 0 !important;
}
.legal-page hr {
  margin: 40px 0 !important;
  border-top: 2px solid rgb(26, 18, 60);
}
.legal-page .legal-page-area {
  width: 95%;
  max-width: 335px;
  margin: 0 auto;
  margin-top: 40px;
}
@media (768px <= width) {
  .legal-page .legal-page-area {
    max-width: 728px;
  }
}
@media (992px <= width) {
  .legal-page .legal-page-area {
    max-width: 765px;
    margin: 40px 0 0 26%;
  }
}
.legal-page .legal-page-top {
  width: 100%;
  padding: 40px 20px;
  text-align: left;
  background-color: rgb(10, 5, 21);
}
@media (768px <= width) {
  .legal-page .legal-page-top {
    padding: 60px 20px;
  }
}
@media (992px <= width) {
  .legal-page .legal-page-top {
    padding: 120px 0 60px;
  }
}
.legal-page .legal-page-top h1 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-weight: 900;
}
@media (992px <= width) {
  .legal-page .legal-page-top h1 {
    margin-left: 26% !important;
    font-size: 40px;
  }
}
.legal-page .legal-page-subtitle p, .legal-page .legal-page-last-updated p {
  font-size: 14px;
  font-weight: 400;
  text-align: right;
}
@media (768px <= width) {
  .legal-page .legal-page-subtitle p, .legal-page .legal-page-last-updated p {
    font-size: 16px;
  }
}
.legal-page .legal-page-last-updated p {
  line-height: 21px !important;
}
.legal-page .legal-page-introduction p {
  margin: 40px 0 !important;
}
.legal-page .legal-page-content {
  margin: 20px auto 40px;
}
@media (768px <= width) {
  .legal-page .legal-page-content {
    margin: 40px auto 60px;
  }
}
@media (992px <= width) {
  .legal-page .legal-page-content {
    margin: 80px auto 120px;
  }
}
.legal-page .legal-page-content p:has(strong), .legal-page .legal-page-content .text-title p {
  margin: 20px 0 !important;
}
.legal-page .legal-page-content p:not(.text-content):has(strong) strong,
.legal-page .legal-page-content .text-title p {
  line-height: normal;
  font-size: 24px !important;
  color: rgb(26, 18, 60);
  letter-spacing: 0.768px;
}
@media (992px <= width) {
  .legal-page .legal-page-content p:not(.text-content):has(strong) strong,
  .legal-page .legal-page-content .text-title p {
    font-size: 24px;
  }
}
.legal-page .legal-page-content p,
.legal-page .legal-page-content .text-content p,
.legal-page .legal-page-content .text-content p:has(strong) strong {
  font-size: 18px;
  color: rgba(35, 39, 42, 0.75);
  line-height: 32px;
  letter-spacing: 0.512px;
}
@media (992px <= width) {
  .legal-page .legal-page-content p,
  .legal-page .legal-page-content .text-content p,
  .legal-page .legal-page-content .text-content p:has(strong) strong {
    font-size: 18px;
  }
}
@media (992px > width) {
  .legal-page .legal-page-content p,
  .legal-page .legal-page-content .text-content p,
  .legal-page .legal-page-content .text-content p:has(strong) strong {
    font-size: 14px;
  }
}
@media (992px > width) {
  .legal-page .legal-page-content p,
  .legal-page .legal-page-content .text-content p,
  .legal-page .legal-page-content .text-content p:has(strong) strong {
    font-size: 14px;
  }
}
.legal-page .legal-page-content p a,
.legal-page .legal-page-content .text-content p a,
.legal-page .legal-page-content .text-content p:has(strong) strong a {
  color: rgb(88, 101, 242);
}
.legal-page .legal-page-content .text-content p {
  margin: 20px auto !important;
}
.legal-page .legal-page-content .text-content:not(:last-child):not(.legal-page-introduction)::after {
  width: 100%;
  height: 1px;
  content: "";
  display: block;
  background-color: rgb(26, 18, 60);
  margin: 40px auto;
}
.legal-page .legal-page-content .legal-page-last-updated p {
  margin-bottom: 40px !important;
  color: rgba(35, 39, 42, 0.9);
  font-size: 18px;
  font-weight: 400;
}
.legal-page .legal-page-content ul, .legal-page .legal-page-content ol {
  margin-left: 60px;
  padding: 0;
  color: rgba(35, 39, 42, 0.75);
  line-height: 32px;
}
@media (992px <= width) {
  .legal-page .legal-page-content ul, .legal-page .legal-page-content ol {
    font-size: 16px;
  }
}
.legal-page .legal-page-content ul li:not(:last-child), .legal-page .legal-page-content ol li:not(:last-child) {
  margin-bottom: 20px;
}
.legal-page .legal-page-content ul li:first-child, .legal-page .legal-page-content ol li:first-child {
  margin-top: 20px;
}
.legal-page .legal-page-content li::marker {
  color: rgb(84, 59, 239);
}