@import 'styles/variables';

#order-details {
    background-position: center;
    background-size: cover;

    .header {
        position: relative;
    }

    .order-buttons-container {
        width: auto !important;
        padding: 0 !important;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 0.5rem;

        .right-button {
            margin-left: 1rem;
        }

        @media (max-width: $phone) {
            flex-direction: column;

            .right-button {
                margin-left: 0;
            }
        }
    }

    .order-title {
        font-size: $extrahugetitle;

        @media (max-width: $desktop) {
            font-size: $hugeplus;
        }

        @media (max-width: $phone) {
            font-size: $morehuge;
        }
    }

    .product-area {
        margin: 0 10px 0 10px;

        @media (max-width: $phone) {
            margin: 0;
        }
    }

    .about-order-text {
        text-align: justify;
    }

    .qr-code-image {
        height: 78px;
    }

    .chip {
        padding: 3px 8px;
        border-radius: 20px;
    }

    #download-pdf-button .mdc-button__ripple::after,
    #download-pdf-button:hover.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before {
        background-color: unset;
        opacity: 0;
    }

    .credit-card-icon {
        max-height: 24px;

        @media (max-width: $desktop) {
            max-height: 21px;
        }
    }
    
    .payment-method-icon {
        max-width: 24px;
        max-height: 100%;
    }
    
    .character-width {
        max-width: 115px;
        margin-right: 22px;

        @media (max-width: $desktop) {
            max-width: unset;
            margin-right: unset;
        }
    }

    .product-name-width {
        max-width: 180px;

        @media (max-width: $desktop) {
            max-width: 75px;
        }
    }

    .product-row {
        background: rgba(243, 245, 247, 1);
    }

    .image {
        width: 60px;
        height: 60px;

        img {
            width: 60px;
            height: 60px;
        }
        
        span {
            font-size: 40px;
        }
    }

    .blue-border-bottom {
        border-bottom: solid 1px $primaryborder;
    }

    .gray-border-bottom {
        border-bottom: solid 1px $grayborder;
    }

    .button-blue-border {
        border: 1px solid $blue;
    }

    .main-content-padding {
        margin-top: 20px;
        margin-bottom: 120px;

        @media (max-width: $desktop) {
            padding-top: 50px;
            margin-bottom: 60px;
        }

        @media (max-width: $phone) {
            padding-top: 20px;
            margin-bottom: 30px;
        }
    }

    .text-payment {
        @media (max-width: $phone) {
            font-size: 12px;
        }
    }

    .main-content-block {
        padding: 82px 50px 26px 50px;

        @media (max-width: $desktop) {
            padding: 40px 25px 13px 25px;
        }

        @media (max-width: $phone) {
            padding: 40px 12px 13px 12px;
        }
    }
    
    .main-margin {
        margin: 0 50px 0 50px;

        @media (max-width: $desktop) {
            margin: 0 25px 0 25px;
        }

        @media (max-width: $phone) {
            margin: 0;
        }
    }

    .payment-instructions p {
        display: inline;
        font-family: $primaryfont;
        line-height: 0;
    }

    @media (max-width: $phone) {
        .complete-icon {
            max-width: 72px;
            max-height: 70px;
        }

        .payment-verification-icon {
            max-width: 14px;
            max-height: 14px;
        }
    }

    @media (min-width: $desktop) {
        .container {
            max-width: 922px;
            margin-top: 83px;
        }
    }

    @media (min-width: $tablet) and (max-width: $desktop) {
        .container {
            max-width: 602px;
            margin-top: 40px;
        }
    }

    @media (max-width: $tablet) {
        .container {
            width: 96%;
            max-width: none;
            margin-top: 40px;
        }
    }

    @media (max-width: $phone) {
        .container {
            width: 89%;
            margin-top: 63px;
        }
    }

    .mdc-button--outlined {
        text-transform: unset !important;
    }

    .submit-button {
        width: 192px;
        height: 50px;

        @media (max-width: $desktop) {
            width: 305px !important;
        }
    }

    .order-button {
        width: 300px;
        height: 50px;
        border-radius: 12px !important;

        @media (max-width: $desktop) {
            width: 100%;
        }

        @media (max-width: $tablet) {
            width: 218px;
        }

        @media (max-width: $phone) {
            width: 300px;
        }
    }

    .livechat-styling {
        background-color: $white !important;
        border-color: $ds-home-button;
        color: $ds-home-button;
        transition: 0.4s ease;

        span {
            color: $ds-home-button;
            margin-right: 0.5rem;
            transition: 0.4s ease;
        }

        &:hover {
            background-color: $ds-home-button !important;
            border-color: transparent;
            color: $white;

            span {
                color: $white;
            }
        }
    }

    .order-span {
        margin-left: 5px;
    }

    .black-filter {
        filter: brightness(0) saturate(100%);
    }

    .order-notes {
        margin-top: 26px;
    }

    .primary-outlined {
        border: 1px solid rgba(88, 101, 242, 1) !important;
        border-radius: 4px !important;
        background-color: $white;
    }

    #resend-invoice-button {
        border: none;
        background-color: $palegray;
        color: $ds-home-button;
    }

    .primary-outlined:not(:disabled) {
        color: rgba(88, 101, 242, 1) !important;
    }

    .purple-text {
        color: rgba(88, 101, 242, 1) !important;
    }

    .text-banner {
        @media (max-width: $phone) {
            margin-top: 95px !important;
        }
    }
}
