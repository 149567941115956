@use 'styles/cg-grid' as grid;
@use '@chicksgroup/styles/variables' as v-global;
@use '@chicksgroup/styles/colors';
@import "styles/mixins";
@import "styles/variables";

#faq {
    @include flex($align: initial, $justify: center, $direction: row );
    background-color: $color-grey-100;
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100vw auto;
    margin-top: 60px;

    /* 
        Collapse Icon transition
    */    
    --cds-collapse-icon-rotate-duration: .50s;
    --cds-collapse-transition-timing-function: cubic-bezier(0.17,0,0.38,0.99);
    --cds-collapse-transition-time: .50s;
    --cds-collapse-icon-rotation: -180deg;

    /* 
        Vars
    */
    --collapse-side-icon-size: 16px;
    --collapse-question-answer-line-height: 20px;
    --collapse-bg: var(--cx-primary-100);
    --cds-search-bg: var(--cg-grey-200);
    --scrollbar-bg: var(--ds-primary-700);

    /* 
        Collapse Spacing
    */
    --cds-collapse-header-padding: #{v-global.$regularPad};
    --cds-collapse-header-gap: 16px;

    /* 
        Collapse Color (Category)
    */
    --cds-collapse-closed-background-color-hover: var(--ds-primary-300);
    --cds-collapse-closed-background-color-active: var(--ds-primary-300);
    
    --cds-collapse-open-background-color-hover: var(--ds-primary-300);
    --cds-collapse-open-background-color-active: var(--ds-primary-300);
    --cds-collapse-open-background-color: var(--ds-primary-300);
    --cds-collapse-background-color: #{$color-grey-200};
    --cds-collapse-background-color-hover: #{$primary-200};
    --cds-collapse-background-color-active: #{$primary-200};
    --cds-collapse-content-background-color: transparent;
    --cds-collapse-open-icon-color: var(--cg-grey-900);
    --cds-question-collapse-background-color: rgb(246, 246, 253);
    --cds-question-collapse-header-question-color: var(--cg-grey-900);

    /*
        Collapse Border
    */
    --cds-collapse-header-outline: 1px solid transparent;

    /* 
        Collapse Font
    */
    --cds-question-collapse-open-line-clamp: 2;
    --cds-question-collapse-header-question-font-size: 16px;
    --cds-question-collapse-header-question-line-height: 20px;
    --cds-question-collapse-header-question-letter-spacing: 0.04px;
    --cds-question-collapse-header-question-font-weight: 500;
    --cds-question-collapse-header-question-font-family: 'Roboto', sans-serif;
    --cds-question-collapse-content-height: calc(var(--collapse-question-answer-line-height) * 20);

    /* 
        Collapse Scrollbar
    */
    --cds-question-collapse-scrollbar-thumb-bg: var(--scrollbar-bg);
    --cds-question-collapse-scrollbar-thumb-hover: var(--scrollbar-bg);

    /* 
        Searchbar Color
    */
    --cds-primary-color-50: var(--ds-primary-700); // Indicator color
    --cds-primary-color: var(--ds-primary-700); // Indicator color - focus

    --cds-text-field-focus-floating-label-color: var(--cg-grey-600);
    --cds-text-field-icon-color: #{$color-grey-700};
    
    --cds-input-background: var(--cds-search-bg);
    --cds-input-focus-background-color: #{$color-grey-200};
    
    --md-filled-text-field-focus-label-text-color: var(--cg-grey-600);
    
    --md-filled-text-field-focus-caret-color: #{$color-grey-700};
    --md-filled-text-field-focus-input-text-color: var(--cg-grey-600);
    
    --md-filled-text-field-caret-color: #{$color-grey-700};
    --md-filled-text-field-leading-icon-color: #{$color-grey-700};
    
    --md-filled-text-field-trailing-icon-color: rgba(82, 82, 82, 1);
    --md-filled-text-field-label-text-color: var(--cg-grey-700);
    --md-filled-field-trailing-content-color: rgba(82, 82, 82, 1);
    --md-filled-text-field-hover-label-text-color: #{$color-grey-700};
    --md-filled-text-field-hover-state-layer-color: #{$color-grey-200};
    --md-filled-text-field-hover-input-text-color: #{$color-grey-700};
    
    --md-filled-text-field-active-label-text-color: var(--cg-grey-600);
    
    --md-filled-text-field-container-color: #{$color-grey-200};
    
    --md-filled-text-field-input-text-color: var(--cg-grey-600);
    --md-filled-text-field-input-text-placeholder-color: var(--cg-grey-600);
    --cds-text-field-floating-label-color: var(--cg-grey-600);

    /* 
        Searchbar Font
    */
    --md-filled-text-field-input-text-weight: 400;

    /* 
        Searchbar Spacing
    */
    --cds-text-field-leading-icon-start-margin: 16px;
    --cds-text-field-leading-icon-end-margin: 8px;

    /* 
        Searchbar Size
    */
    --cds-text-field-leading-icon-size: 18px;
    --cds-text-field-width: 400px;
    --cds-text-field-height: 52px;

    /*
        Searchbar pointer
    */
    --cds-text-field-trailing-icon-cursor: pointer;

    /* 
        Question Collapse Color
    */
    --cds-question-collapse-fade-start-color: rgba(255, 255, 255, 0.2);
    --cds-question-collapse-fade-end-color: rgba(252, 252, 252, 1);

    /* 
        Question Collapse Spacing
    */
    --cds-question-collapse-fade-height: calc(var(--collapse-question-answer-line-height) * 3);
    --cds-question-collapse-header-padding: unset;
    --cds-question-collapse-scrollbar-padding: 0;


    cds-collapse {
        --md-icon-size: var(--collapse-side-icon-size);
    }

    cds-search {
        --md-icon-size: 24px;
        --cds-text-field-leading-icon-size: 24px;
        --cds-text-field-trailing-icon-size: 12px;

        &:focus {
            --cds-text-field-default-border: 3px solid transparent;
        }

        &.trailing-icon {
            --cds-icon-color: var(--cg-grey-700);
            --md-icon-color: transparent;
            --cds-text-field-leading-icon-size: 16px;    

        }
    }

    @media(width <= $desktop) {
        margin-top: 60px;
    } 

    @media(width <= $tablet) {
        --cds-question-collapse-content-height: calc(var(--collapse-question-answer-line-height) * 13);
    }

    @media(width <= $phone){
        --cds-question-collapse-content-height: calc(var(--collapse-question-answer-line-height) * 28);
    }

    /*
        Drawer
    */
    .container-search {
        --cds-drawer-z-index: 10000;
        --cds-drawer-position: fixed;

        /*
            Close Button Color
        */
        --md-text-button-label-text-weight: 500;
        --md-text-button-icon-color: var(--ds-primary-700);
        --md-text-button-hover-icon-color: var(--ds-primary-700);
        --md-text-button-pressed-icon-color: var(--ds-primary-700);

        --cds-button-max-width: fit-content;
        --cds-button-width: fit-content;

        --cds-drawer-header-close-button-text-color: var(--ds-primary-700);
        --cds-drawer-header-close-button-hover-text-color: var(--ds-primary-700);
        --cds-drawer-header-close-button-focus-text-color: var(--ds-primary-700);
        --cds-drawer-header-close-button-pressed-text-color: var(--ds-primary-700);

        --md-filled-icon-button-icon-color: #{$color-grey-100};

        /*
            Close Button Font
        */
        --cds-drawer-header-close-button-text-size: 16px;
        --cds-drawer-header-close-button-hover-text-size: 24px;
        --cds-drawer-header-close-button-text-weight: 500;

        /*
            Close Button Icon Size
        */
        --cds-drawer-close-button-icon-size: 16px;

        /* 
            Open Button Color
        */
        --md-filled-icon-button-container-color: var(--ds-primary-700);
        --md-filled-icon-button-hover-state-layer-color: var(--ds-primary-800);
        --md-filled-icon-button-hover-state-layer-opacity: var(--ds-primary-800);
        --md-filled-icon-button-pressed-state-layer-color: var(--ds-primary-900);
        --md-filled-icon-button-pressed-state-layer-opacity: var(--ds-primary-900);

        /* 
            Open Button Size
        */
        --md-filled-icon-button-container-width: 100%;
        --md-filled-icon-button-container-height: 52px;
        --md-filled-icon-button-container-shape: 4px;

        /* 
            Drawer Color
        */
        --cds-drawer-divider-color: var(--cg-grey-300);

        /* 
            Drawer Spacing
        */
        --cds-drawer-header-padding: 12px #{v-global.$regularPad};
        --cds-drawer-header-space: 16px;
        --cds-drawer-header-close-button-width: 92px;
        --cds-drawer-header-close-button-trailing-space: 0px;

        /* 
            Drawer color
        */
        --cds-drawer-bg: #FCFCFC;
    }

    .no-results {
        .no-results-message {
            line-height: 20px;
            letter-spacing: 0.04px;
        }

        min-width: 490px;
        --cds-large-button-space-icon-content: 20px;
        --cds-large-button-padding-inline: 0px;
        --cds-large-button-padding-block: 0px;
        --cds-large-button-description-font-weight: 300;
        --cds-large-button-box-min-height: 40px;
        --cds-large-button-description-font-size: 16px;
        --cds-large-button-truncate-lines: none;
        --cds-large-button-description-line-height: 20px;
        --cds-large-button-description-color: var(--cg-grey-900);
        --cds-large-button-box-padding-inline: 0px;
        --cds-large-button-box-padding-block: 0px;
        --cds-large-button-default-background: transparent;
        --cds-large-button-hover-background: transparent;
        --cds-large-button-icon-color: #{$color-grey-700};

        @media(width <= $tablet) {
            min-width: 90px;
        }
    }

    .drawer-content {
        max-height: calc(100vh - 70px);
    }

    .drawer-search {
        max-height: 52px;
        flex-grow: 1;
    }

    .slot-header-search {
        --cds-text-field-height: 52px;
        --md-filled-text-field-label-text-size: 14px;


        --md-filled-text-field-with-label-top-space: 10px;
        width: 100%;
    }

    .section-column-mobile {
        --md-icon-size: 16px;
        @include grid.cg-col(v-global.$regularPad, 2, 2);
        @include flex-gap(v-global.$regularPad, $direction: column);
        padding: 20px;
    }

    .container-question-section {
        @include grid.cg-grid-row(v-global.$regularPad, true);
        border: 1px solid $color-grey-300;
        border-radius: 4px;
        padding: $largeplus;

        .section-column {
            @include grid.cg-col(v-global.$regularPad, 2, 1, $desktop);
            @include grid.cg-col(v-global.$regularPad, 2, 2);
            @include flex-gap(v-global.$regularPad, $direction: column);
        }
        
        @media (width <= $desktop) {
            border:none;
            padding: 0 !important;
        }
    }

    .faq-section-container {
        @include column-gap(16px);
    }

    .line-clamp {
        @include truncate-text(4);
        overflow: hidden;

        @media (width > $tablet) {
            -webkit-line-clamp: 2;
        }
    }

    @media(width <= $tablet) {
        --cds-text-field-width: 300px;
    }

    @media(width <= $phone) {
        --cds-text-field-width: 100%;
    }

    .reduced-duration-animation {
        --cds-collapse-transition-time: .50s;
    }

    .extra-duration-animation {
        --cds-collapse-transition-time: .78s;
    }

    .section-header {
        word-break: break-all;
        @include font($medium, 600, 20px, 0px, var(--cg-grey-900));
        font-family: $primaryfont;
        text-align: left;
        margin: 0;
        display: -webkit-box;
    }

    div[data-simplebar]:has(.page-host) {
        max-height: calc(100% - 70px);
    }

    .container-header {
        max-height: 52px;
        @include flex(center, space-between);

        .container-heading-text {
            @include font($size: $hometitletablet, $weight: 700, $height: 36px, $spacing: normal, $color: var(--cg-grey-900));

            @media (width <= $tablet) {
                @include font($size: $extralarge, $height: 28px);
            }
        }
    }


    @media (width <= $tablet) {
        background-size: 200vw auto;
    }


    .search-mobile {
        display: none;
        width: 72px;
        height: 52px;

        @media (width <= $phone) {
            display: block;
        }
    }

    .question-card-border-container {
        height: 100%;
        border: 1px solid #{$color-grey-300};
        overflow: hidden;
        border-top: none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .question-card-border {
        border: 1px solid #{$color-grey-300};
        border-top: none;
        border-right: none;
        border-left: none;
    }

    .hover-whole-question {
        &:active {
            --cds-collapse-open-background-color-hover: var(--ds-primary-200);
            --cds-collapse-open-background-color-active: var(--ds-primary-200);
        }

        &:hover {
            --cds-collapse-content-background-color: var(--ds-primary-200);
            --cds-collapse-open-background-color:var(--ds-primary-200);
            --cds-question-collapse-content-background-color:var(--ds-primary-200);
            --cds-collapse-open-background-color-active: var(--ds-primary-200);
            --cds-collapse-background-color: var(--ds-primary-200);
        }
    }

    .question-card {
        --cds-collapse-open-header-border-radius: 0;
        --cds-collapse-open-padding-transition: .25s;
        --cds-question-collapse-open-line-clamp: 2;
        --cds-collapse-open-background-color: #{$white};
        --cds-question-collapse-line-clamp: 2;
        --cds-collapse-open-padding: 26px 20px 8px;
        --cds-collapse-header-border-radius: 0px;
        --cds-collapse-header-padding: 26px 20px;

        --cds-question-collapse-content-background-color: #{$color-grey-100}; // Content
        --cds-collapse-background-color: #{$color-grey-100}; // Default
        --cds-collapse-closed-background-color-active: var(--ds-primary-200); // Closed - Active
        --cds-collapse-closed-background-color-hover: var(--ds-primary-200); // Closed - Hover
        --cds-collapse-open-background-color-hover: var(--ds-primary-200);// Open - Hover
        --md-icon-size: var(--collapse-side-icon-size);
        --cds-collapse-header-outline: transparent;

        @media(width <= $tablet) {
            --cds-question-collapse-open-line-clamp: 4;
            --cds-question-collapse-line-clamp: 4;
            --cds-collapse-open-padding: #{v-global.$regularPad} #{v-global.$regularPad} 8px;
        }

        .question-content {
            @include font($size: $regular, $weight: 400, $height: var(--collapse-question-answer-line-height), $spacing: 0.07px, $color: $color-grey-700);
            word-break: break-word;
            font-family: $primaryfont;
            padding: 0 $extra-tiny v-global.$regularPad v-global.$regularPad;
            margin: 0 !important;  
        }
    }
    
    .search-bar, .drawer-search {
        max-height: 52px;
        --cds-text-field-height: 18.1px;
        --md-filled-text-field-with-label-top-space: 10px;
    }
    
    .search-bar {

        @media(width <= $tablet){
            --cds-text-field-width: 252px;
        }

        @media (width <= $phone) {
            display: none;
        }
    }

    .category-card-icon {
        color: $ds-faq-purple;
        width: 23px !important;
        height: 23px !important;
    }


    .container-category-card-image {
        @include flex-center();
        width: 24px;
        height: 24px;

        .category-card-image {
            width: 24px;
            height: 100%;
        }
    }

    .container-section-icon {
        @include flex-center();
        --md-icon-size: 32px;
        box-sizing: border-box;
        max-width: 32px;
        max-height: 32px;
        flex-shrink: 0;
    }

    .faq-container {
        @include column-gap(v-global.$bigPad);
        width: 1560px;
        margin: $hugePad $largeplus;

        @media(width <= $tablet) {
            @include padding-x(0px);
            margin: v-global.$largePad v-global.$regularPad;
        }

        @media(width <= $phone) {
            margin: v-global.$bigPad v-global.$regularPad;
        }
    }

    .divider-mobile {
        height: 1px;
        opacity: 100%;
        background-color: $color-grey-300;
        margin: 0;
        display: none;
        border: none;
        margin-top: v-global.$regularPad;

        @media (width <= $desktop) {
            display: block;
        }
    }


}

@media (width <= $desktop) {
    .faq-container {
        max-width: unset;
    }
}

