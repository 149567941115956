@import 'styles/variables';

.legal-page {
    padding-top: 60px;

    @media ($desktop <= width) {
        padding-top: 80px;
    }

    h1, p {
        margin: 0 !important;
    }

    hr {
        margin: 40px 0 !important;
        border-top: 2px solid $menu-purple;
    }

    .legal-page-area {
        width: 95%;
        max-width: 335px;
        margin: 0 auto;
        margin-top: 40px;

        @media ($tablet <= width) {
            max-width: 728px;
        }

        @media ($desktop <= width) {
            max-width: 765px;
            margin: 40px 0 0 26%;
        }
    }

    .legal-page-top {
        width: 100%;
        padding: 40px 20px;
        text-align: left;
        background-color: rgba(10, 5, 21, 1);

        @media ($tablet <= width) {
            padding: 60px 20px;
        }

        @media ($desktop <= width) {
            padding: 120px 0 60px;
        }

        h1 {
            color: $white;
            font-size: $extralarge;
            font-weight: 900;

            @media ($desktop <= width) {
                margin-left: 26% !important;
                font-size: $extrahugetitle;
            }
        }
    }

    .legal-page-subtitle p, .legal-page-last-updated p {
        font-size: $regular;
        font-weight: 400;
        text-align: right;
        
        @media ($tablet <= width) {
            font-size: $medium;
        }
    }

    .legal-page-last-updated p {
        line-height: 21px !important;
    }

    .legal-page-introduction p {
        margin: 40px 0 !important;
    }

    .legal-page-content {
        margin: 20px auto 40px;

        @media ($tablet <= width) {
            margin: 40px auto 60px;
        }

        @media ($desktop <= width) {
            margin: 80px auto 120px;
        }

        p:has(strong), .text-title p {
            margin: 20px 0 !important;
        }

        p:not(.text-content):has(strong) strong, 
        .text-title p {
            line-height: normal;
            font-size: $extralarge !important;
            color: $menu-purple;
            letter-spacing: 0.768px;

            @media ($desktop <= width) {
                font-size: $extralarge;
            }
        }

        p, 
        .text-content p, 
        .text-content p:has(strong) strong {
            font-size: $large;
            color: $ds-content;
            line-height: 32px;
            letter-spacing: 0.512px;

            @media ($desktop <= width) {
                font-size: $large;
            }

            @media ($desktop > width) {
                font-size: $regular;
            }

            @media ($desktop > width) {
                font-size: $regular;
            }
            
            a {
                color: $ds-home-button;
            }
        }

        .text-content p {
            margin: 20px auto !important;
        }

        .text-content:not(:last-child):not(.legal-page-introduction)::after {
            width: 100%;
            height: 1px;
            content: '';
            display: block;
            background-color: $menu-purple;
            margin: 40px auto;
        }

        .legal-page-last-updated p {
            margin-bottom: 40px !important;
            color: rgba(35, 39, 42, 0.90);
            font-size: $large;
            font-weight: 400;
        }

        ul, ol {
            margin-left: 60px;
            padding: 0;
            color: $ds-content;
            line-height: 32px;

            @media ($desktop <= width) {
                font-size: $medium;
            }

            li:not(:last-child) {
                margin-bottom: 20px;
            }

            li:first-child {
                margin-top: 20px;
            }
        }

        li::marker {
            color: $sign-up-blue;
        }
    }
}
