@import 'styles/variables';

#home {
    margin-top: 245px;

    @media (max-width: $desktop) {
        margin-top: 144px;
    }

    @media ($ultrahdscreen <= width) {
        margin-top: 445px;
    }

    .home-title {
        max-width: 748px;
        font-family: $primaryfont;
        color: $ds-home;
        font-size: $hometitlesize;
        text-align: center;
        letter-spacing: 1.28px;
        line-height: 84px;

        @media (max-width: $desktop) {
            max-width: 441px;
            font-size: $hometitletablet;
            line-height: 48px;
        }

        @media (max-width: $phone) {
            max-width: 213px;
            font-size: $extralarge;
            line-height: 36px;
        }
    }

    .home-description {
        font-family: $primaryfont;
        color: $ds-home;
        font-size: $huge;
        text-align: center;
        letter-spacing: 1.28px;
        line-height: 42px;

        p {
            margin-bottom: 100px;
        }

        @media(max-width:$extrascreen) {
            p {
                max-width: 736px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        @media (max-width: $desktop) {
            font-size: $medium;
            line-height: 24px;

            p {
                max-width: 442px;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 60px;
            }
        }

        @media (max-width: $phone) {
            font-size: $regular;
            line-height: 21px;

            p {
                margin-bottom: 43px;
                max-width: 350px;
                margin-right: auto;
                margin-left: auto;
            }
        }
    }

    .grid-center {
        max-width: 1226px;
        margin-left: auto;
        margin-right: auto;

        .card-grid {
            display: grid;
            grid-template-columns: repeat(2, 587px);
            grid-column-gap: 50px;
            grid-row-gap: 0px;
            grid-template-rows: 1fr;
            margin-bottom: 152px;
        }

        @media (max-width: $extrascreen) {
            max-width: 542px;

            .card-grid {
                display: grid;
                grid-template-columns: 1fr;
                grid-row-gap: 60px;
                grid-column-gap: 0px;
                grid-template-rows: repeat(2, 307px);
                margin-bottom: 152px;
            }
        }

        @media (max-width: $phone) {
            max-width: 315px;

            .card-grid {
                grid-template-rows: repeat(2, 420px);
                grid-row-gap: 50px;
                margin-bottom: 70px;
            }
        }

        @media ($ultrahdscreen <= width) {
            max-width: 1626px;

            .card-grid {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}
